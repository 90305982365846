/* eslint-disable react/prop-types */
/* eslint-disable no-case-declarations */
import React from 'react';
import { graphql } from 'gatsby';
import parse, { attributesToProps } from 'html-react-parser';

import LayoutMain from 'layouts/layout-main';
import useIntersectionObserver from 'hooks/use-intersection-observer';

import Hero from 'components/pages/pillar-page/hero';
import Content from 'components/shared/content';
import Subscribe from 'components/shared/subscribe';
import ModalDownloadForm from 'components/shared/modal-download-form';
import HighlightedList from 'components/lazy-blocks/highlighted-list';
import CompactTitleAndVideo from 'components/lazy-blocks/compact-title-and-video';
import PdfBannerWithButton from 'components/lazy-blocks/pdf-banner-with-button';
import HubspotFormWrapper from 'components/lazy-blocks/hubspot-form-wrapper';
import SubscribeForm from 'components/lazy-blocks/subscribe-form';
import BannerWithButton from 'components/lazy-blocks/banner-with-button';

const PillarPage = ({ data: { wpPage: data }, pageContext, location }) => {
  useIntersectionObserver('.use-intersection-observer', {
    rootMargin: '200px',
  });

  // https://github.com/remarkablemark/html-react-parser#htmlparser2
  // The library does parsing on client side differently from server side
  // it results in having a need of passing htmlparser2 to adjust behavior
  // according to the client side behavior
  const reactedContent = parse(pageContext.content, {
    htmlparser2: {
      lowerCaseAttributeNames: true,
    },
    // eslint-disable-next-line consistent-return,react/no-unstable-nested-components
    replace: (domNode) => {
      const props = attributesToProps(domNode.attribs);

      if (domNode.type === 'tag') {
        switch (domNode.name) {
          case 'highlightedlist':
            const items = JSON.parse(props.items);
            return <HighlightedList title={props.title} items={items} />;
          case 'compacttitleandvideo':
            const videoAutoplay = !!Number(props.videoautoplay);
            const videobackgroundimage = JSON.parse(props.videobackgroundimage);
            return (
              <CompactTitleAndVideo
                title={props.title}
                description={props.description}
                quote={props.quote}
                video={props.video}
                isVideoAutoplay={videoAutoplay}
                videoBackgroundImage={videobackgroundimage}
              />
            );
          case 'pdfbannerwithbutton':
            return (
              <PdfBannerWithButton
                title={props.title}
                description={props.description}
                url={props.url}
                buttonText={props.buttontext}
              />
            );
          case 'hubspotform':
            return (
              <HubspotFormWrapper
                title={props.title}
                description={props.description}
                isCompact={props['is-compact']}
                formId={props['form-id']}
                id={props.id}
              />
            );
          case 'subscribeform':
            return <SubscribeForm title={props.title} description={props.description} />;
          case 'bannerwithbutton':
            return (
              <BannerWithButton
                title={props.title}
                description={props.description}
                buttonText={props['button-text']}
                buttonUrl={props['button-url']}
                isCompact={props['is-compact']}
                background={props.background}
              />
            );
          default:
            return undefined;
        }
      }
    },
  });

  return (
    <LayoutMain
      seo={{ ...data.seo, date: pageContext.date }}
      footerTheme="with-border"
      headerWithGradient={false}
      withBanner={pageContext.showBanner}
      pageContext={pageContext}
      location={location}
    >
      <Hero
        title={data.acf.heroTitle}
        description={data.acf.heroDescription}
        buttonText={data.acf.heroButtonText}
        buttonUrl={data.acf.heroButtonUrl}
      />
      <Content content={reactedContent} uri={data.uri} extraLinks={data.acf.extraLinks} />
      <Subscribe />
      {data.acf.fileForDownloading && (
        <ModalDownloadForm
          id={data.acf.fileForDownloading.id}
          postId={data.acf.fileForDownloading.databaseId}
          fileUrl={data.acf.fileForDownloading.acf.file.guid}
          title={data.acf.fileForDownloading.acf.title}
          description={data.acf.fileForDownloading.acf.description}
        />
      )}
    </LayoutMain>
  );
};

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      acf {
        heroTitle
        heroDescription
        heroButtonText
        heroButtonUrl
        fileForDownloading {
          ... on WpFileDownloading {
            id
            databaseId
            acf {
              title
              description
              file {
                guid
              }
            }
          }
        }
        extraLinks {
          url
          linkText
        }
      }
      ...wpPageSeo
    }
  }
`;

export default PillarPage;
