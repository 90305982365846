import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Button from 'components/shared/button';
import Heading from 'components/shared/heading';
import MainContext from 'context/main';

import styles from './hero.module.scss';

// import shape from './svg/shape.svg';

const cx = classNames.bind(styles);

const Hero = ({ title, description, buttonText, buttonUrl }) => {
  const { isTableOfContent } = useContext(MainContext);

  return (
    <section className={cx('wrapper')}>
      <div className={cx('container', 'inner', { isTableOfContent })}>
        <Heading className={cx('title')} size="xl">
          {title}
        </Heading>
        <p className={cx('description', { withoutButton: !buttonText || !buttonUrl })}>
          {description}
        </p>
        {buttonText && buttonUrl && (
          <Button className={cx('button')} to={buttonUrl} theme="accent-primary">
            {buttonText}
          </Button>
        )}

        {/* <img className={cx('shape')} src={shape} aria-hidden alt="" /> */}
      </div>
    </section>
  );
};

Hero.defaultProps = {
  buttonText: null,
  buttonUrl: null,
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
};

export default Hero;
